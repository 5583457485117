import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const InventoryCalendar = () => import('@/views/settings/inventories/InventoryCalendar')
const CalendarManagement = () => import('@/views/calendarview/CalendarView')

const Coupons = () => import('@/views/coupons/List')
const CreateCoupon = () => import('@/views/coupons/Create')

const PaymentMethods = () => import('@/views/settings/paymentMethods/List')
const CreatePaymentMethods = () => import('@/views/settings/paymentMethods/Create')

const TaxClasses = () => import('@/views/settings/taxclasses/List')
const CreateTaxClass = () => import('@/views/settings/taxclasses/Create')

const GuestTypes = () => import('@/views/guesttypes/List')
const CreateGuestType = () => import('@/views/guesttypes/Create')

const GuestGroups = () => import('@/views/guestgroups/List')
const CreateGuestGroup = () => import('@/views/guestgroups/Create')

const Inventories = () => import('@/views/settings/inventories/List')
const CreateInventory = () => import('@/views/settings/inventories/Create')

const Experiences = () => import('@/views/experiences/List')
const CreateExperience = () => import('@/views/experiences/Create')
const Instance = () => import('@/views/Instance')

const Languages = () => import('@/views/languages/List')
const CreateLanguage = () => import('@/views/languages/Create')

const Accounts = () => import('@/views/accounts/List')
const CreateAccount = () => import('@/views/accounts/Create')

const Currencies = () => import('@/views/currencies/List')
const CreateCurrency = () => import('@/views/currencies/Create')

const ExperienceStatuses = () => import('@/views/experiencestatuses/List')
const Finance = () => import('@/views/finance/List')

const CreateExperienceStatus = () => import('@/views/experiencestatuses/Create')

const PartnerLanguages = () => import('@/views/settings/languages/List')
const CreatePartnerLanguage = () => import('@/views/settings/languages/Create')

const PartnerCurrencies = () => import('@/views/settings/currencies/List')
const CreatePartnerCurrency = () => import('@/views/settings/currencies/Create')

const PartnerIntegrations = () => import('@/views/settings/integrations/List')
const CreatePartnerIntegrations = () => import('@/views/settings/integrations/Create')

const Customers = () => import('@/views/customers/List')
const CreateCustomer = () => import('@/views/customers/Create')

const Bookings = () => import('@/views/bookings/List')
const ViewBooking = () => import('@/views/bookings/Details')

const Categories = () => import('@/views/categories/List')
const CreateCategory = () => import('@/views/categories/Create')

const TransactionReports = () => import('@/views/reports/transactionreport')

const EditExperienceSchedule = () => import('@/views/experiences/schedule/Create')
const CreateExperienceRow = () => import('@/views/experiences/schedule/CreateRow')

const Partners = () => import('@/views/partners/List')
const CreatePartner = () => import('@/views/partners/Create')

const Integrations = () => import('@/views/integrations/List')
const CreateIntegrations = () => import('@/views/integrations/Create')

const AddOns = () => import('@/views/settings/addons/List')
const CreateAddOn = () => import('@/views/settings/addons/Create')

const Policies = () => import('@/views/settings/policies/List')
const CreatePolicy = () => import('@/views/settings/policies/Create')

const CancellationPolicies = () => import('@/views/settings/cancellationpolicies/List')
const CreateCancellationPolicy = () => import('@/views/settings/cancellationpolicies/Create')

const Companies = () => import('@/views/settings/companies/List')
const CreateCompany = () => import('@/views/settings/companies/Create')

const Contracts = () => import('@/views/contracts/List')
const CreateContract = () => import('@/views/contracts/Create')

const GeneralSettings = () => import('@/views/general/General')

const Closures = () => import('@/views/settings/closures/List')
const CreateClosure = () => import('@/views/settings/closures/Create')

const Templates = () => import('@/views/settings/templates/List')
const CreateTemplate = () => import('@/views/settings/templates/Create')
Vue.use(Router)

export default new Router({
  mode: 'hash',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'instance',
          name: 'Instance',
          component: Instance
        },
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'coupons',
          meta: { label: 'Coupons' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Coupons
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Coupon' },
              name: 'Create Coupon',
              component: CreateCoupon
            },
          ]
        },
        {
          path: 'experiences',
          meta: { label: 'Experiences' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Experiences
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Experience' },
              name: 'Create Experience',
              component: CreateExperience
            },
            {
              path: ':experienceId/schedule/:id',
              meta: { label: 'Scheduling and Breakdown Details' },
              name: 'Experience Schedule',
              component: EditExperienceSchedule,
            },
            {
              path: ':experienceId/closure/:id',
              meta: { label: 'Create closure' },
              name: 'Create closure',
              component: CreateClosure,
            },
            {
              path: 'schedule/day/:dayId/row/:id',
              meta: { label: 'Manage Row' },
              name: 'Experience Row',
              component: CreateExperienceRow,
            }
          ]
        },
        {
          path: 'accounts',
          meta: { label: 'Accounts' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Accounts
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Account' },
              name: 'Create Account',
              component: CreateAccount
            }
          ]
        },
        {
          path: 'partners',
          meta: { label: 'Partners' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Partners
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Partner' },
              name: 'Create Partner',
              component: CreatePartner
            }
          ]
        },
        {
          path: 'integrations',
          meta: { label: 'Integrations' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Integrations
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Integration' },
              name: 'Create Integration',
              component: CreateIntegrations
            }
          ]
        },
        {
          path: 'contracts',
          meta: { label: 'Contracts' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Contracts
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Contract' },
              name: 'Create Contract',
              component: CreateContract
            }
          ]
        },
        {
          path: 'languages',
          meta: { label: 'Languages' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Languages
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Language' },
              name: 'Create Language',
              component: CreateLanguage
            }
          ]
        },
        {
          path: 'currencies',
          meta: { label: 'Currencies' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Currencies
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Currency' },
              name: 'Create Currency',
              component: CreateCurrency
            }
          ]
        },
        {
          path: 'experiencestatuses',
          meta: { label: 'Experience Statuses' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: ExperienceStatuses
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Experience Status' },
              name: 'Create Experience Status',
              component: CreateExperienceStatus
            },
          ]
        },
        {
          path: 'finance',
          meta: { label: 'Finance' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Finance
            }
          ]
        },
        {
          path: '/settings/languages',
          meta: { label: 'Languages' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: PartnerLanguages
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Language' },
              name: 'Create',
              component: CreatePartnerLanguage
            },
          ]
        },
        {
          path: '/settings/currencies',
          meta: { label: 'Currencies' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: PartnerCurrencies
            },
            {
              path: 'create',
              meta: { label: 'Create Currency' },
              name: 'Create',
              component: CreatePartnerCurrency
            },
          ]
        },
        {
          path: '/settings/integrations',
          meta: { label: 'Integrations' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: PartnerIntegrations
            },
            {
              path: '/settings/integrations/create/:id',
              meta: { label: 'Create Partner Integration' },
              name: 'Create Partner Integration',
              component: CreatePartnerIntegrations
            }
          ]
        },

        {
          path: '/settings/general',
          meta: { label: 'Settings' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: GeneralSettings
            }
          ]
        },

        {
          path: '/settings/paymentmethods',
          meta: { label: 'Payment Methods' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: PaymentMethods
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Payment Methods' },
              name: 'Create Payment Methods',
              component: CreatePaymentMethods
            },
          ]
        },
        {
          path: '/settings/closures',
          meta: { label: 'Closures' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Closures
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Closure' },
              name: 'Create Closure',
              component: CreateClosure
            },
          ]
        },
        {
          path: '/settings/taxclasses',
          meta: { label: 'Tax Classes' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: TaxClasses
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Tax Class' },
              name: 'Create Tax Class',
              component: CreateTaxClass
            }
          ]
        },
        {
          path: '/guesttypes',
          meta: { label: 'Guest Types' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: GuestTypes
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Guest Type' },
              name: 'Create Guest Type',
              component: CreateGuestType
            }
          ]
        },
        {
          path: '/guestgroups',
          meta: { label: 'Guest Groups' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: GuestGroups
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Guest Group' },
              name: 'Create Guest Group',
              component: CreateGuestGroup
            }
          ]
        },
        {
          path: '/settings/inventories',
          meta: { label: 'Inventories' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Inventories
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Inventory' },
              name: 'Create Inventory',
              component: CreateInventory,
            },
            {
              path: 'calendarview',
              name: 'InventoryCalendar',
              component: InventoryCalendar,
            },
          ]
        },
        {
          path: '/settings/policies',
          meta: { label: 'Policies' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Policies
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Policy' },
              name: 'Create Policy',
              component: CreatePolicy
            }
          ]
        },
        {
          path: '/settings/templates',
          meta: { label: 'Templates' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Templates
            },
            {
              path: 'edit/:code',
              meta: { label: 'Update template' },
              name: 'Update template',
              component: CreateTemplate
            }
          ]
        },
        {
          path: '/settings/cancellationpolicies',
          meta: { label: 'CancellationPolicies' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: CancellationPolicies
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Cancellation Policy' },
              name: 'Create Cancellation Policy',
              component: CreateCancellationPolicy
            }
          ]
        },

        {
          path: 'customers',
          meta: { label: 'Customers' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Customers
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Customer' },
              name: 'Create Customer',
              component: CreateCustomer
            }
          ]
        },

        {
          path: 'companies',
          meta: { label: 'Companies' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Companies
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Company' },
              name: 'Create Company',
              component: CreateCompany
            }
          ]
        },
        {
          path: 'bookings',
          meta: { label: 'Bookings' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Bookings
            },
            {
              path: 'details/:id',
              meta: { label: 'View Details' },
              name: 'View Details',
              component: ViewBooking
            }
          ],

        },
        {
          path: 'reports',
          meta: { label: 'Reports' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: TransactionReports
            }
          ]
        },

        {
          path: 'calendarmanagement',
          meta: { label: 'Calendar Management' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: CalendarManagement
            }
          ],
        },
        {
          path: 'categories',
          meta: { label: 'Categories' },
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Categories
            },
            {
              path: 'create/:id',
              meta: { label: 'Create Category' },
              name: 'Create Category',
              component: CreateCategory
            }
          ]
        },
        {
          path: 'support',
          component: {
            render(c) {
              window.open('https://www.stayify.com/support', '_blank');
              return c('div', 'Support is offered at https://www.stayify.com/support');
            }
          }
        },
      ]
    },
    {
      path: '/',
      redirect: '/auth',
      name: 'Auth',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: 'auth',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
      ]
    },
    {
      path: '/search-experience',
      name: 'Search Experiences',
      component: TheContainer
    }
  ]
})
