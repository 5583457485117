import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  asideShow: false,
  darkMode: false,
  instance: '', // partner name
  partnerId: '', // partner id
  activeTopLevelPath: '/dashboard' // top level path (e.g. /experiences), excluding /instance 
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  toggle(state, variable) {
    state[variable] = !state[variable]
  },
  instance(state, variable) {
    state.instance = variable
  },
  partnerId(state, variable) {
    state.partnerId = variable
  },
  setActiveTopLevelPath(state, variable) {
    state.activeTopLevelPath = variable
  }
}

const getters = {
  getInstance(state) {
    return state.instance;
  }
};

export default new Vuex.Store({
  state,
  mutations
})