<template>
  <router-view></router-view>
</template>

<script>
import Vue from 'vue'
import FormBuilder from 'v-form-builder';
import { SchedulePlugin } from '@syncfusion/ej2-vue-schedule';

Vue.use(SchedulePlugin);
Vue.component('FormBuilder', FormBuilder);
export default {
  name: 'App'
}
import {VueFormBuilderPlugin} from 'v-form-builder'
import 'v-form-builder/dist/v-form-builder.css' //load CSS from your App
Vue.use(VueFormBuilderPlugin, {})
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
  @import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-schedule/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
</style>
