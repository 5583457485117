import axios from "axios";
import Vue from "vue";
import router from '@/router/index'
axios.interceptors.request.use(
  (config) => {
    const partnerId = localStorage.getItem("partner_id");
    config.headers["partner-id"] = partnerId;
    const token = localStorage.getItem("api_token");
    if (token) {
      const authorization = `Bearer ${token}`;
      config.headers["authorization"] = authorization;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },

  function (error) {
    const originalConfig = error.config;
    if (originalConfig.url !== "/auth" && error.response) {
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        const accessToken = localStorage.getItem("api_token");
        const refreshToken = localStorage.getItem("api_refresh_token");
        const tokenData = {
          accessToken: accessToken,
          refreshToken: refreshToken,
        };

        axios
          .post(
            `${Vue.prototype.$apiAdress}/v1/Account/RefreshToken`,
            tokenData
          )
          .then(function (response) {
            localStorage.setItem("api_token", response.data.accessToken);
            localStorage.setItem(
              "api_refresh_token",
              response.data.refreshToken
            );
            error.config.headers[
              "Authorization"
            ] = `bearer ${response.data.accessToken}`;
          })
          .catch(function (error) { 
              localStorage.clear();
          });
        return axios(error.config);
      } else {
        if(error.response)
        {  
          if(error.response.status === 401 && router.currentRoute.path !== "/auth" && router.currentRoute.path !== "/register")
          { 
            localStorage.clear();
            router.push({ path: "/auth" });
          }
        }
        return Promise.reject(error);
      }
    }
  }
);

export default axios;
