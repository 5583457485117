// Example of SVG converted to js array, so it can be used with CIcon.
// the first argument is two last values of svg viewBox,
// the second argument is the SVG content stripped of SVG tags
export const logo = ['642 134', `
<g>
  <g style="fill:#ffffff;">
      <path class="cls-1" d="M16.21,21.61a7.19,7.19,0,0,0-4.27-1.53c-2.9,0-4.35,1.09-4.35,3.29,0,.92.77,2,2.31,3.32L12.63,29c.84.72,1.43,1.25,1.75,1.58a7.47,7.47,0,0,1,1,1.38,7.87,7.87,0,0,1,.81,1.79,6.47,6.47,0,0,1,.27,1.85,6.69,6.69,0,0,1-2.71,5.67,11.48,11.48,0,0,1-7.06,2A15.12,15.12,0,0,1,0,41.77l1.52-4.64A9,9,0,0,0,7.14,39a5.79,5.79,0,0,0,3.2-.83,2.61,2.61,0,0,0,1.28-2.34c0-1-.77-2.1-2.32-3.3L6.47,30.23a20.21,20.21,0,0,1-1.76-1.52,7.95,7.95,0,0,1-1.73-3,6.57,6.57,0,0,1-.25-1.83,7.29,7.29,0,0,1,2.42-5.78,9.24,9.24,0,0,1,6.34-2.13,23.36,23.36,0,0,1,3.32.3,11.11,11.11,0,0,1,2.84.68Z" transform="translate(0 -0.73)" />
      <path class="cls-1" d="M32.93,27.14H27.6l-2,8.58a8.42,8.42,0,0,0-.23,1.72c0,1.32.75,2,2.24,2a10.18,10.18,0,0,0,3.25-.94v4.06a16.78,16.78,0,0,1-5.31.67,4.73,4.73,0,0,1-3.54-1.37,4.93,4.93,0,0,1-1.34-3.58,6.89,6.89,0,0,1,.16-1.43l2.19-9.7H20.83l.78-3.63h2.24l.76-4,5.16-1.88-1.35,5.84h5.29Z" transform="translate(0 -0.73)" />
      <path class="cls-1" d="M150.24.73a29.85,29.85,0,0,0-25.85,14.94h4.78a25.81,25.81,0,1,1-4.77,14.92c0-.13,0-.25,0-.38l-3.3,6.9A29.86,29.86,0,1,0,150.24.73ZM127.88,17.67h-4.55a29.6,29.6,0,0,0-3,12.92c0,1,.05,2.07.15,3.08l4.86-10.12A24.87,24.87,0,0,1,127.88,17.67Z" transform="translate(0 -0.73)" />
      <path class="cls-1" d="M161.8,17.32l-17.16,3.34a1,1,0,0,0-.77.71l-6,21A1,1,0,0,0,139,43.62l17.16-3.34a1,1,0,0,0,.78-.71l6-21A1,1,0,0,0,161.8,17.32Zm-11.38,16.8a3.65,3.65,0,1,1,3.64-3.65A3.64,3.64,0,0,1,150.42,34.12Z" transform="translate(0 -0.73)" />
      <path class="cls-1" d="M51.83,35.92a8.45,8.45,0,0,0-.12,1.48,16.31,16.31,0,0,0,.61,5.46H49.5c-.84,0-1.47-.65-1.88-1.93a7.84,7.84,0,0,1-5.76,2.29A6.48,6.48,0,0,1,37,41.45a6.76,6.76,0,0,1-1.72-4.93,14.11,14.11,0,0,1,3.33-9.44A10.55,10.55,0,0,1,47,23.15a19.94,19.94,0,0,1,7.06.9Zm-5.22-9.09a5.49,5.49,0,0,0-4.84,2.9A12.34,12.34,0,0,0,40,36.41c0,2.08,1,3.12,3.07,3.12,1.3,0,2.44-.8,3.41-2.4S48.27,32.22,49,27.2A8.59,8.59,0,0,0,46.61,26.83Z" transform="translate(0 -0.73)" />
      <path class="cls-1" d="M67.08,44.79q-2.7,5.63-10.44,5.66V46.09c3.41,0,5.59-1.11,6.56-3.3l.93-2.17c-1.65-.47-2.76-2.21-3.34-5.2L58.5,23.51h4.75q2.11,12.72,2.69,13.28l6.22-13.28h5.11Z" transform="translate(0 -0.73)" />
      <path class="cls-1" d="M83.92,42.86H79.33l2.93-15.72H79.78l.76-3.63h7ZM82.58,17.29a2.66,2.66,0,0,1,.86-2,3.1,3.1,0,0,1,4.16,0,2.76,2.76,0,0,1,0,4,3.08,3.08,0,0,1-4.15,0A2.64,2.64,0,0,1,82.58,17.29Z" transform="translate(0 -0.73)" />
      <path class="cls-1" d="M105.65,19.9a6.25,6.25,0,0,0-2.51-.65c-1.73,0-2.93,1.42-3.58,4.26H104l-.45,3.63H99l-2.89,17a7.47,7.47,0,0,1-2.54,4.55,7.37,7.37,0,0,1-4.86,1.81l-1-3a4.87,4.87,0,0,0,2.31-1.29,4.15,4.15,0,0,0,1.27-2.25l3-16.77H91.61l.6-3.63h2.67q1.88-7.94,8.48-7.93a6.19,6.19,0,0,1,3.2.78Z" transform="translate(0 -0.73)" />
      <path class="cls-1" d="M123.09,17.67l-9,19.12q-.58-.57-2.69-13.28h-4.76l2.3,11.9q.87,4.5,3.34,5.21l-.94,2.17q-1.44,3.28-6.56,3.3v4.36q7.76,0,10.45-5.66l5.32-11.12,4.86-10.12,2.81-5.88Z" transform="translate(0 -0.73)" />
    </g>
  </g>
</g>
`]

