import 'core-js/stable'
import Vue from 'vue'
import CoreuiVuePro from '../node_modules/@coreui/vue-pro/src/index.js'
import App from './App'
import router from './router/index'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import i18n from './i18n.js'
import VueAxios from 'vue-axios';
import axios from './plugins/axios';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import FormBuilder from 'v-form-builder';
import { registerLicense } from '../node_modules/@syncfusion/ej2-base';
import moment from 'moment';

Vue.use(VueAxios, axios);

Vue.component('form-builder', FormBuilder); // Register the component globally
registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9ed3VWQmNYUEJ+WUU=');
router.beforeEach((to, from, next) => {
  // save top level path which could be used in case of instance (partner) change
  if (to.path !== '/instance') {
    let topLevelPath = '/' + to.path.split('/')[1];
    if (topLevelPath === '/settings') {
      topLevelPath += '/' + to.path.split('/')[2];
    }
    store.commit('setActiveTopLevelPath', topLevelPath)
  }
  if (to.path === '/widgetpaymentreturnurl') {
    next({ path: '/#/dashboard/#/payment' });
  } else { // NB! next must not be called multiple times, must be in if/else. https://router.vuejs.org/guide/advanced/navigation-guards#Optional-third-argument-next
    next();
  }
});

fetch("config.json")
  .then(response => response.json())
  .then(config => {

    Vue.prototype.$apiAdress = config.api;
    Vue.prototype.$widgetSrc = config.widgetSrc;
    Vue.prototype.$widgetApi = config.widgetApi;
    Vue.use(CoreuiVuePro)
    Vue.use(BootstrapVue)
    Vue.prototype.moment = moment;
    Vue.prototype.$log = console.log.bind(console)
    Vue.prototype.$dateFormat = "DD.MM.yyyy HH:mm";
    Vue.prototype.$dateFormatInput = "dd.MM.yyyy HH:mm";
    axios
        .get(`${config.api}/v1/Language/GetByCode/en-US`)
        .then(function (response) {
          Vue.prototype.$dateFormat = response.data.datetimeFormat.replace("dd", "DD").replace("tt", "A");
          Vue.prototype.$dateFormatInput = response.data.datetimeFormat.replace("tt", "a");
        });
    new Vue({
      el: '#app',
      router,
      store,
      icons,
      i18n,
      template: '<App/>',
      components: {
        App
      }
    })
  });
